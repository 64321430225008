<template>
  <b-container fluid>
    <StrapiEditoComponent
      :societe="exploitationCourante.codeSociete"
    ></StrapiEditoComponent>
    <StrapiCollectionComponent :collection="collection" :societe="societe">
    </StrapiCollectionComponent>
  </b-container>
</template>

<script>
import StrapiEditoComponent from "../../components/strapi/StrapiEditoComponent.vue";
import StrapiCollectionComponent from "../../components/strapi/StrapiCollectionComponent.vue";
export default {
  name: "StrapiReglementsCampagneView",
  components: {
    StrapiEditoComponent,
    StrapiCollectionComponent,
  },
  computed: {
    collection() {
      return this.$store.getters["strapi/collection"]("reglements-campagne");
    },
    exploitationCourante() {
      return this.$store.getters["expl/adherentCourant"];
    },
    societe() {
      return this.$store.getters["societe"](
        this.exploitationCourante.codeSociete
      );
    },
  },
};
</script>
